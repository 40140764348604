@import "variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

body {
    font-family: $base-font-family;
}

html {
    font-size: 0.875rem;
    font-family: $base-font-family;
}

@include media-breakpoint-up(sm) {
    html {
        font-size: 1rem;
    }
}

@include media-breakpoint-up(md) {
    html {
        font-size: 1.125rem;
    }
}

h1 {
    font-size: $heading-1-font-size;
    font-weight: $font-weight-heavier;
    font-family: $base-font-family;
}

h2 {
    font-size: $heading-2-font-size;
    font-weight: $font-weight-heavier;
    font-family: $base-font-family;
}

h3 {
    font-size: $heading-3-font-size;
    font-weight: $font-weight-heavier;
    font-family: $base-font-family;
    text-align: center;
}

h4 {
    font-size: $heading-4-font-size;
    font-weight: $font-weight-huskie;
    font-family: $base-font-family;
}

h5 {
    font-weight: $font-weight-huskie;
    font-family: $base-font-family;
}
